<template>
  <div v-if="user">
    <portal to="pageHeaderRight">
      <submit-button :isSaving="isSaving"/>
    </portal>
    <div>
      <form id="editInterfaceSettings" @submit.prevent="formSubmit">
        <input type="submit" id="submitForm" hidden=""/>
        <div class="grid-x grid-margin-x">
          <div class="cell medium-8">
            <div class="card card__section">
              <minimum-profile-data v-model="user" :validator="$v.user"/>
              <div class="spacer--30"></div>
              <ab-input-field
                :label="$t('forms.function')"
                v-model="user.function"
                :error="$v.user.function.$error"
                :errorMessage="$t('forms.no_valid_data')"
              ></ab-input-field>
              <ab-input-field
                :label="$t('forms.new_password')"
                type="password"
                autocomplete="new-password"
                v-model="user.password"
              ></ab-input-field>
              <ab-input-field
                :label="$t('forms.password_does_not_match')"
                type="password"
                autocomplete="new-password"
                v-model="user.password_confirmation"
              ></ab-input-field>
              <ab-select
                :label="$t('app.language')"
                v-model="user.locale"
                :options="locales"
              ></ab-select>
            </div>
          </div>
          <div class="cell medium-4">
            <div class="grid-x align-middle">
              <div class="cell shrink pr-20">
                <div class="account__icon">
                  <div class="vue-dropzone-single">
                    <ab-dropzone
                      :data="user.avatar"
                      :id="user.id"
                      :relation="'user'"
                    />
                  </div>
                </div>
              </div>
              <div class="cell auto">
                <span class="account__name"
                >{{ user.name }} {{ user.surname }}</span
                >
                <span class="account__function">{{ user.function }}</span>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import backend from '@/backend'
import { email, minLength, required } from 'vuelidate/lib/validators'
import MinimumProfileData from '@/views/profile/components/MinimumProfileData'

export default {
  name: 'ProfileInfo',
  data () {
    return {
      user: null,
      isSaving: false,
      locales: [{ title: 'English', value: 'en_EN' }, { title: 'Nederlands', value: 'nl_NL' }]
    }
  },
  created () {
    backend.get(`${process.env.VUE_APP_URL}/me`).then(response => {
      this.setUser(response.data)
    })
  },
  methods: {
    setUser (data) {
      this.user = data
      if (this.user.avatar == null) this.user.avatar = {}
      this.$store.state.user = this.user

      this.$i18n.locale = this.user.locale
      localStorage.setItem('locale', this.user.locale)
      this.$i18n.ensureTranslations()
    },
    formSubmit (e) {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.isSaving = true
        backend
          .put(`${process.env.VUE_APP_URL}/me`, this.user)
          .then(response => {
            this.setUser(response.data)
            this.$notify({
              group: 'custom-template',
              title: false,
              position: 'bottom left',
              text: this.$t('notify.changes_saved')
            })
            this.isSaving = false
          })
          .catch(() => {
            this.$notify({
              group: 'custom-template-error',
              title: false,
              position: 'bottom left',
              text: this.$t('notify.unable_to_save_changes')
            })
            this.isSaving = false
          })
      }
    }
  },
  components: {
    'minimum-profile-data': MinimumProfileData
  },
  validations: {
    user: {
      name: {
        required,
        minLength: minLength(1)
      },
      surname: {
        required,
        minLength: minLength(1)
      },
      email: {
        required,
        email,
        minLength: minLength(1)
      },
      function: {
        required,
        minLength: minLength(1)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.alert {
  border: 1px solid #cc3e3e;
  color: #cc3e3e;

  i {
    font-size: 16px;
    margin-right: 15px;
  }
}
</style>
